import {
  BoundDateField,
  Button,
  Css,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
  useTestIds,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { format } from "date-fns";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import {
  SaveTradePartnerAvailabilityRequestInput,
  SchedulesPage_TaskFragment,
  TradePartnerAvailabilityRequestStatus,
  useSaveTradePartnerAvailabilityRequestMutation,
  useUpdateTradePartnerAvailabilityRequestStatusMutation,
} from "src/generated/graphql-types";
import { DateOnly } from "src/utils";
import { ConfirmationActionType } from "./SchedulesPage";

interface ConfirmOrRescheduleModalProps {
  selectedTasks: SchedulesPage_TaskFragment[];
  type: ConfirmationActionType;
}

export function ConfirmOrRescheduleModal({ selectedTasks, type }: ConfirmOrRescheduleModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();
  const [updateTradePartnerAvailabilityRequestStatus] = useUpdateTradePartnerAvailabilityRequestStatusMutation({});
  const [saveTradePartnerAvailabilityRequest] = useSaveTradePartnerAvailabilityRequestMutation({});
  const tids = useTestIds({}, "scheduleConfirmationModal");
  const formState = useFormState({
    config: formConfig,
  });
  const isConfirmType = type === "Confirm";

  const onSubmit = useCallback(() => {
    // TODO: validate this eslint-ignore
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    isConfirmType
      ? updateTradePartnerAvailabilityRequestStatus({
          variables: {
            input: {
              ids: selectedTasks.flatMap(
                ({ tradePartnerAvailabilityRequests }) => tradePartnerAvailabilityRequests?.map(({ id }) => id) ?? [],
              ),
              status: TradePartnerAvailabilityRequestStatus.Confirmed,
            },
          },
          onCompleted: () => {
            triggerNotice({
              message: `You marked ${selectedTasks.length} task as confirmed`,
            });
          },
          refetchQueries: ["SchedulesPage"],
        })
      : saveTradePartnerAvailabilityRequest({
          variables: {
            input: {
              id: selectedTasks.first?.tradePartnerAvailabilityRequests?.first?.id ?? "",
              status: TradePartnerAvailabilityRequestStatus.RescheduleNeeded,
              rescheduleDates: mapToInput(formState.value.rescheduleDates),
            },
          },
          onCompleted: () => {
            triggerNotice({
              message: `You marked 1 task as rescheduled`,
            });
          },
          refetchQueries: ["SchedulesPage"],
        });
    closeModal();
  }, [
    selectedTasks,
    closeModal,
    updateTradePartnerAvailabilityRequestStatus,
    triggerNotice,
    isConfirmType,
    saveTradePartnerAvailabilityRequest,
    formState.value,
  ]);

  return (
    <>
      <ModalHeader>{isConfirmType ? "Confirm" : "Reschedule"}</ModalHeader>
      <ModalBody>
        <FormLines>
          {isConfirmType ? (
            "Are you sure you want to confirm your availability for this task(s)?"
          ) : (
            <div css={Css.gray700.sm.$}>
              Are you sure you want to reschedule this task(s)? You may provide new availability by selecting a date
              below.
            </div>
          )}
          {selectedTasks.map(({ id, interval, name }) => (
            <div css={Css.df.jcsb.mt3.$} key={id}>
              <span css={Css.smMd.$}>{name}</span>
              {isConfirmType && (
                <span css={Css.gray700.sm.$} {...tids.taskDates}>{`${format(interval.startDate, "dd MMM")} - ${format(
                  interval.endDate,
                  "dd MMM",
                )}`}</span>
              )}
            </div>
          ))}
          {type === "Reschedule" && (
            <BoundDateField
              label="Reschedule"
              field={formState.rescheduleDates}
              labelStyle="hidden"
              disabledDays={[{ before: new Date() }]}
              required
            />
          )}
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Observer>
          {() => (
            <Button
              label={isConfirmType ? "Confirm" : "Reschedule"}
              disabled={isConfirmType ? false : !formState.valid}
              onClick={onSubmit}
            />
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormValue = Omit<SaveTradePartnerAvailabilityRequestInput, "rescheduleDates"> & {
  rescheduleDates: Date | null | undefined;
};

const formConfig: ObjectConfig<FormValue> = {
  rescheduleDates: { type: "value", rules: [required] },
};

function mapToInput(rescheduleDates: Date | null | undefined): DateOnly[] {
  return rescheduleDates ? [new DateOnly(rescheduleDates)] : [];
}
