import {
  Banner,
  Button,
  CollapseToggle,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  ModalProps,
  Palette,
  RowStyles,
  ScrollableContent,
  SelectToggle,
  actionColumn,
  column,
  emptyCell,
  formatDate,
  simpleHeader,
  useComputed,
  useGridTableApi,
  useModal,
} from "@homebound/beam";
import { addDays, isSameDay, startOfToday, subDays } from "date-fns";
import { useMemo, useState } from "react";
import { QueryResultHandler, SearchBox, dateCell } from "src/components";
import {
  DateOperation,
  FeatureFlagStatus,
  FeatureFlagType,
  SchedulesPageQuery,
  SchedulesPage_TaskFragment,
  SchedulesPage_TradePartnerAvailabilityRequestFragment,
  TaskStatus,
  TradePartnerAvailabilityRequestStatus,
  TradePartnerTaskStatus,
  useSchedulesPageQuery,
} from "src/generated/graphql-types";
import { useCurrentUser } from "src/hooks";
import { DateOnly, foldEnum, isDefined } from "src/utils";
import { TableActions } from "../layout/TableActions";
import { ConfirmOrRescheduleModal } from "./ConfirmOrRescheduleModal";

enum SchedulesPageGrouping {
  UPCOMING = "upcoming",
  PAST = "past",
}

interface SchedulesPageViewProps {
  tasks: SchedulesPage_TaskFragment[];
}

export type ConfirmationActionType = "Confirm" | "Reschedule";

export function SchedulesPage() {
  const { tradePartnerUsers } = useCurrentUser();
  const today = new DateOnly(startOfToday());
  const sixtyDaysFromToday = new DateOnly(subDays(today, 60));
  const leadTime = tradePartnerUsers?.flatMap((u) => u.tradePartner.leadTimeInDays).sort().first ?? 0;
  // if the leadTime is greater than 60 days, then use the exact lead time, otherwise use 60 days as a default
  const leadTimeWindow = new DateOnly(addDays(today, leadTime > 60 ? leadTime : 60));
  const markets = tradePartnerUsers?.flatMap((u) => u.tradePartnerContact?.markets.map((m) => m.id) ?? []).unique();

  const query = useSchedulesPageQuery({
    variables: {
      market: markets,
      tradePartner: tradePartnerUsers?.map((u) => u.tradePartner.id),
      startDateRange: {
        op: DateOperation.Between,
        value: sixtyDaysFromToday,
        value2: leadTimeWindow,
      },
    },
    skip: !isDefined(tradePartnerUsers) || tradePartnerUsers.isEmpty || markets?.isEmpty,
  });

  return QueryResultHandler<SchedulesPageQuery>({
    result: query,
    render: ({ tasks }) => <SchedulesPageView tasks={filteredTasks(tasks)} />,
  });
}

export function SchedulesPageView({ tasks }: SchedulesPageViewProps) {
  const today = useMemo(() => {
    return new DateOnly(startOfToday());
  }, []);
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const { openModal } = useModal();
  const tableApi = useGridTableApi<NestedRow>();
  const selectedTaskRows = useComputed(() => tableApi.getSelectedRows("task"), [tableApi]);
  // sorting by startDate since the column sorting will mess up the three groupings
  const sortedTasks = tasks.sortBy((st) => st.interval.startDate);

  // tasks with a current TPAR that have not been completed
  const tradePartnerAvailabilityRequestTasks = useMemo(() => {
    return sortedTasks.filter(
      (t) => t.tradePartnerAvailabilityRequests?.nonEmpty && t.status.code !== TaskStatus.Complete,
    );
  }, [sortedTasks]);

  // tasks that have not been requested yet, are not in the past, and are not completed
  const upcomingTasks = useMemo(() => {
    return sortedTasks.filter(
      (t) =>
        t.tradePartnerAvailabilityRequests?.isEmpty &&
        t.interval.startDate >= today &&
        t.status.code !== TaskStatus.Complete,
    );
  }, [sortedTasks, today]);

  // completed tasks that are in the past
  const pastTasks = useMemo(() => {
    return sortedTasks.filter((t) => t.status.code === TaskStatus.Complete && t.interval.startDate < today);
  }, [sortedTasks, today]);

  // check to see if any of our tasks has a TPAR and is in a needs (re)confirmation status
  const hasOpenTradePartnerAvailabilityRequests = tradePartnerAvailabilityRequestTasks.some(
    (st) =>
      st.tradePartnerAvailabilityRequests?.nonEmpty &&
      (st.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsConfirmation ||
        st.tradePartnerStatus.code === TradePartnerTaskStatus.NeedsReconfirmation),
  );

  return (
    <ScrollableContent virtualized>
      <div css={Css.bgGray100.h100.w100.fg1.py4.px2.bgGray100.ifSm.pt3.$}>
        <div css={Css.mxa.maxwPx(1280).$}>
          <div css={Css.bgWhite.px4.pt1.pb4.$}>
            <div css={Css.df.fdr.my4.mb4.jcsb.ifSm.fdc.mt2.$}>
              <div css={Css.xl2Sb.ifSm.mb2.lgSb.$} data-testid="scheduleHeader">
                Schedule
              </div>
              {tasks.nonEmpty && (
                <TableActions>
                  <SearchBox onSearch={setSearchFilter} />
                </TableActions>
              )}
            </div>
            <div css={Css.mb3.$}>
              {hasOpenTradePartnerAvailabilityRequests && (
                <Banner message={"You have tasks that require availability confirmation."} type="warning" />
              )}
            </div>

            <div css={Css.oxa.$}>
              <ScrollableContent>
                {tasks.isEmpty ? (
                  <div css={Css.df.fdc.aic.jcc.gap2.bgGray100.vh75.$}>
                    <img src="/images/coming-soon.svg" />
                    <span css={Css.xlSb.gray800.$}>Coming Soon!</span>
                    <span css={Css.xsMd.gray700.tac.maxwPx(250).$}>
                      None of the lots that you’re assigned to are enabled yet. Stay tuned while we roll out this
                      functionality on more lots!
                    </span>
                  </div>
                ) : (
                  <GridTable
                    as="table"
                    columns={createColumns(today, openModal, selectedTaskRows)}
                    rows={createRows(tradePartnerAvailabilityRequestTasks, upcomingTasks, pastTasks)}
                    rowStyles={rowStyles}
                    style={{ bordered: false, allWhite: true }}
                    sorting={{ on: "client", initial: ["releasedOn", "DESC"] }}
                    api={tableApi}
                    filter={searchFilter}
                  />
                )}
              </ScrollableContent>
            </div>
          </div>
        </div>
      </div>
    </ScrollableContent>
  );
}
type HeaderRow = { kind: "header"; id: string; data: undefined };
type GroupRow = {
  kind: "grouping";
  data: { title: string; subTitle: string };
  children: TaskRow[];
};
export type TaskRow = {
  kind: "task";
  id: string;
  data: SchedulesPage_TaskFragment;
};
type NestedRow = HeaderRow | GroupRow | TaskRow;

const rowStyles: RowStyles<NestedRow> = {
  grouping: { cellCss: Css.bgGray100.$ },
  task: {
    cellCss: ({ data }) => (data.status.code === TaskStatus.Complete ? Css.xsMd.gray600.$ : Css.xsMd.gray900.$),
  },
};

function createRows(
  tradePartnerAvailabilityRequestTasks: SchedulesPage_TaskFragment[],
  upcomingTasks: SchedulesPage_TaskFragment[],
  pastTasks: SchedulesPage_TaskFragment[],
): GridDataRow<NestedRow>[] {
  const groups = [
    {
      id: SchedulesPageGrouping.UPCOMING,
      title: "Upcoming",
      subTitle: "(Next 60 days)",
      children: upcomingTasks,
    },
    {
      id: SchedulesPageGrouping.PAST,
      title: "Past Tasks",
      subTitle: "(Last 60 days)",
      children: pastTasks,
    },
  ];

  const groupedTradePartnerAvailabilityRequestTasks = tradePartnerAvailabilityRequestTasks.map((c) => ({
    data: c,
    kind: "task" as const,
    id: c.id,
  }));

  const groupedRows = groups.map((group) => ({
    kind: "grouping" as const,
    id: group.id,
    data: { title: group.title, subTitle: group.subTitle },
    initCollapsed: group.id === SchedulesPageGrouping.PAST ? true : false,
    children: group.children.map((c) => ({
      data: c,
      kind: "task" as const,
      id: c.id,
      selectable: isSelectable(c),
    })),
  }));
  return [simpleHeader, ...groupedTradePartnerAvailabilityRequestTasks, ...groupedRows];
}

function isSelectable(task: SchedulesPage_TaskFragment): false | undefined {
  return task.tradePartnerAvailabilityRequests?.isEmpty ? false : undefined;
}

function createColumns(
  today: DateOnly,
  openModal: (props: ModalProps) => void,
  selectedRowIds: GridDataRow<TaskRow>[],
): GridColumn<NestedRow>[] {
  return [
    actionColumn<NestedRow>({
      header: (data, { row }) => <SelectToggle id={row.id} />,
      grouping: (data, { row }) => ({ content: () => <CollapseToggle row={row} compact />, alignment: "left" }),
      task: (data, { row }) => <SelectToggle id={row.id} disabled={data.tradePartnerAvailabilityRequests?.isEmpty} />,
      w: "32px",
    }),

    column<NestedRow>({
      header: "Task",
      grouping: ({ title, subTitle }) => ({
        content: () => (
          <>
            <span css={Css.gray900.xsSb.mr2.$}>{title}</span>
            <span css={Css.truncate.xsSb.gray600.$}>{subTitle}</span>
          </>
        ),
        sortValue: false,
      }),
      task: ({ name }) => ({
        content: name,
        css: Css.xsSb.$,
      }),
      w: "260px",
    }),
    column<NestedRow>({
      header: "Address",
      grouping: emptyCell,
      task: ({ project }) => ({
        content: project?.buildAddress.street1,
      }),
      w: "170px",
    }),
    column<NestedRow>({
      header: "Start",
      grouping: emptyCell,
      task: ({ interval }) => dateCell(interval.startDate),
    }),
    column<NestedRow>({
      header: "End",
      grouping: emptyCell,
      task: ({ interval }) => dateCell(interval.endDate),
    }),
    column<NestedRow>({
      header: "Your Status",
      grouping: emptyCell,
      task: (data, { row }) => ({
        content: () => {
          // grab rescheduled tasks so we can show a tooltip on the reschedule button
          const rescheduledTask = data.tradePartnerAvailabilityRequests?.find(
            (tpar) =>
              tpar.status.code === TradePartnerAvailabilityRequestStatus.RescheduleNeeded ||
              tpar.status.code === TradePartnerAvailabilityRequestStatus.Waiting,
          );
          const tradePartnerTaskStatusMapper = foldEnum(data.tradePartnerStatus.code, {
            NEEDS_CONFIRMATION: " ",
            COMPLETED_JOB: "Completed",
            NEEDS_RECONFIRMATION: " ",
            UNAVAILABLE: "Rescheduled",
            CONFIRMED: "Confirmed",
          });
          // don't show the status if the task is "upcoming"
          const isUpcoming = row.data.tradePartnerAvailabilityRequests?.isEmpty && row.data.interval.startDate > today;
          return isUpcoming ? (
            <></>
          ) : (
            <div css={Css.df.$}>
              {tradePartnerTaskStatusMapper}
              {data.tradePartnerStatus.code === TradePartnerTaskStatus.Unavailable && (
                <Icon
                  icon="infoCircle"
                  color={Palette.Gray500}
                  tooltip={rescheduleTasksTooltip(rescheduledTask)}
                  inc={2}
                  xss={Css.ml1.$}
                />
              )}
            </div>
          );
        },
        sortValue: false,
      }),
      w: "140px",
    }),
    column<NestedRow>({
      header: emptyCell,
      grouping: emptyCell,
      task: (data) => ({
        content: () => {
          const selectedTasks = getSelectedTasks(data, selectedRowIds);

          return (
            data.tradePartnerAvailabilityRequests?.nonEmpty &&
            data.status.code !== TaskStatus.Complete && (
              <Button
                label="Reschedule"
                onClick={() =>
                  openModal({
                    content: <ConfirmOrRescheduleModal type={"Reschedule"} selectedTasks={selectedTasks} />,
                  })
                }
                variant="text"
                disabled={data.tradePartnerAvailabilityRequests.first?.canSave.disabledReasons
                  .map((dr) => dr.message)
                  .join(", ")}
              />
            )
          );
        },
        sortValue: false,
      }),
    }),
    column<NestedRow>({
      header: emptyCell,
      grouping: emptyCell,
      task: (data) => ({
        content: () => {
          // since we can either select a task or directly click on the confirm button, we need to check if the task is already selected
          const selectedTasks = getSelectedTasks(data, selectedRowIds);
          const rescheduled =
            data.tradePartnerAvailabilityRequests?.last?.status.code ===
            TradePartnerAvailabilityRequestStatus.RescheduleNeeded;
          return (
            data.tradePartnerAvailabilityRequests?.nonEmpty &&
            data.status.code !== TaskStatus.Complete && (
              <Button
                label={rescheduled ? "Waiting" : hasNewDate(data) ? "Confirm New Date" : "Confirm"}
                onClick={() =>
                  openModal({
                    content: <ConfirmOrRescheduleModal selectedTasks={selectedTasks} type="Confirm" />,
                  })
                }
                disabled={
                  (data.tradePartnerStatus.code === TradePartnerTaskStatus.Confirmed && "You already confirmed.") ||
                  (rescheduled &&
                    "Waiting on your Homebound contact to confirm the new dates that you rescheduled to.") ||
                  data.tradePartnerAvailabilityRequests.first?.canSave.disabledReasons
                    .map((dr) => dr.message)
                    .join(", ")
                }
              />
            )
          );
        },
        sortValue: false,
      }),
    }),
  ];
}
// rescheduled tasks includes tasks that have been changed by the trade partner or the assignee
function rescheduleTasksTooltip(rescheduledTasks: SchedulesPage_TradePartnerAvailabilityRequestFragment | undefined) {
  const rescheduleDates = rescheduledTasks?.rescheduleDates;
  if (!isDefined(rescheduleDates) || rescheduleDates?.isEmpty) return "";
  return `Rescheduled for ${rescheduleDates.map((date) => formatDate(date, "MMM dd")).join(", ")}`;
}

function getSelectedTasks(data: SchedulesPage_TaskFragment, selectedRowIds: GridDataRow<TaskRow>[]) {
  return [
    data,
    ...selectedRowIds
      .map((st) => st.data)
      // removes duplicate tasks
      .filter((d) => d.id !== data.id)
      // adding this check as we don't want to bulk confirm a task that has already been confirmed or a task that is in an unavailable state
      .filter(
        (d) =>
          d.tradePartnerStatus.code !== TradePartnerTaskStatus.Confirmed &&
          d.tradePartnerAvailabilityRequests?.first?.status.code !==
            TradePartnerAvailabilityRequestStatus.RescheduleNeeded,
      ),
  ];
}

function hasNewDate(data: SchedulesPage_TaskFragment) {
  const { tradePartnerAvailabilityRequests, interval } = data;
  // task must be in a waiting status in order to confirm a new date
  if (tradePartnerAvailabilityRequests?.first?.status.code !== TradePartnerAvailabilityRequestStatus.Waiting) {
    return false;
  }
  // check for new date on the trade partner availability request
  return tradePartnerAvailabilityRequests?.first?.rescheduleDates?.some((d) => !isSameDay(d, interval.startDate));
}

/* We currently have tasks that are in both legacy schedules and dynamic schedules, so we have to do some additional filering.
Since the pilot has both a primary project and a shadow project, we have to remove the duplicate tasks and only show the new tasks from the shadow project (aka pilot project).
After we filter for duplicates, we go through the existing task filter to not break anything with legacy schedules. */
function filteredTasks(tasks: SchedulesPage_TaskFragment[]) {
  return tasks.filter((task) => {
    const hasShadowProjects = task.project?.shadowProjects.nonEmpty;
    if (hasShadowProjects) return false;

    return task.project?.featureFlags.some(
      (ff) =>
        ff.type.code === FeatureFlagType.TradePartnerCommunications && ff.status.code === FeatureFlagStatus.Active,
    );
  });
}
